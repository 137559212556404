import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-pracassessmentlevelwise',
  templateUrl: './pracassessmentlevelwise.component.html',
  styleUrls: ['./pracassessmentlevelwise.component.css']
})
export class PracassessmentlevelwiseComponent implements OnInit {

  UserId;
  pgid;
  standardlabel = new Array();
  Agrade = new Array();
  Bgrade = new Array();
  Cgrade = new Array();
  stackchart: any;
  loading = true;
  totboys = 0;
  totgirls = 0;
  alltotstudent = 0;
  totapercent = 0;
  totbpercent = 0;
  totcpercent = 0;
  private value;

  GetCALAssessmentGraphCountList;
  GetCALAssessmentGraphLevel;
  biglevel = new Array();
  basiclevel = new Array();
  intlevel = new Array();
  advlevel = new Array();
  standardlbl = new Array();
  groupchart: any
  groupchart1: any
  stdlbl = new Array();
  apercent = new Array();
  bpercent = new Array();
  cpercent = new Array();
  dpercent = new Array();
  GetCALAssessmentGraphCountFinalList
  Examtype;
  standardlabel1 = new Array();
  level01 = new Array();
  Beginner1 = new Array();
  Basic1 = new Array();
  Intermediate1 = new Array();
  fiveIntermediate = new Array();
  Advance1 = new Array();
  stackchart4;
  Examtypeval;
  selectfiyear;

  standarddataList;
  onefourstandardlabel = new Array();
  fivstandardlabel = new Array();
  level0 = new Array();
  Beginner = new Array();
  Basic = new Array();
  fivelevel0 = new Array();
  fiveBeginner = new Array();
  fiveBasic = new Array();
  fivIntermediate = new Array();
  fiveAdvance = new Array();
  fivlevel0 = new Array();
  fivBeginner = new Array();
  fivBasic = new Array();

  Advance = new Array();
  mathslevel0 = new Array();
  mathsBeginner = new Array();
  mathsBasic = new Array();
  stackchart1: any;
  stackchart2: any;
  stackchart3: any;
  fivstandarddataList;



  constructor(private service: MasterService,) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    if (this.selectfiyear == '2020-2021') {
      this.Examtype = 'Beginner Level'
    }
    else if (this.selectfiyear == '2021-2022') {
      // this.Examtype = 'Baseline'
      this.Examtype = 'PracticalEndline'
    }
    else if (this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') {
      this.Examtype = 'PracticalBaseline'
    }

    this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "' and exam='" + this.Examtype + "'").subscribe((data: any) => {
      this.GetCALAssessmentGraphCountList = data;
      this.value = this.GetCALAssessmentGraphCountList;
      for (let i = 0; i <= this.GetCALAssessmentGraphCountList.length; i++) {
        this.totboys += this.value[i].boys;
        this.totgirls += this.value[i].girls;
        this.alltotstudent += this.value[i].totstudent;
        this.totapercent += this.value[i].apercent / this.value.length;
        this.totbpercent += this.value[i].bpercent / this.value.length;
        this.totcpercent += this.value[i].cpercent / this.value.length;
        // break;
      }


    })


    let totalstudentcont
    this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "'and exam='" + this.Examtype + "'").subscribe((data: any) => {

      data.forEach(y => {
        if (y.A != 0 || y.B != 0 || y.C != 0) {
          totalstudentcont = y.A + y.B + y.C
          this.standardlabel.push('STD ' + y.standard);
          this.Agrade.push(((y.A * 100) / totalstudentcont).toFixed(2));
          this.Bgrade.push(((y.B * 100) / totalstudentcont).toFixed(2));
          this.Cgrade.push(((y.C * 100) / totalstudentcont).toFixed(2));
        }


      });
      if (this.stackchart) { this.stackchart.destroy(); }
      this.stackchart = new Chart('stackcanvas', {
        type: 'bar',
        data: {
          labels: this.standardlabel,

          datasets: [
            {
              label: 'A Grade (100 >= 76)',
              data: this.Agrade,
              backgroundColor: '#4285F4',
              borderColor: '#4285F4',
              fill: true,

            },

            {
              label: 'B Grade (75  >= 51)',
              data: this.Bgrade,
              backgroundColor: '#F4B400',
              borderColor: '#F4B400',
              fill: true
            },

            {
              label: 'C Grade (50  >= 35)',
              data: this.Cgrade,
              backgroundColor: '#51B8CD',
              borderColor: '#51B8CD',
              fill: true
            },



          ],

        },
        options: {


          legend: {
            display: true,
            position: 'bottom',

          },

          scales: {
            xAxes: [{

              display: true,
              stacked: true,
              distribution: 'series',
              scaleLabel: {
                display: true,
                labelString: 'Standard',

              }

            }],
            yAxes: [{
              display: true,
              stacked: true,

              ticks: {
                beginAtZero: true,
                stepSize: 10,
                max: 100,
                callback: function (value) {
                  return value + "%"
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Percentage',

              }


            }],

          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'white'
            }
          }
        }
      });
      this.loading = false;
    })

    if (this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') {
      this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to122022("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'and academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
        console.log(data);

        data.map((obj) => {
          obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
          obj.IntermediateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
        })

        this.fivstandarddataList = data;
        this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
        this.fivstandarddataList.forEach(X => {
          this.fivstandardlabel.push(X.standard);
          this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
          this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
          this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
          this.fivIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
          this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

        });
        console.log('adv & intrt' + this.fiveBasic);
        if (this.stackchart1) { this.stackchart1.destroy(); }
        this.stackchart1 = new Chart('standardfiv', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.fivstandardlabel,

            datasets: [
              {
                label: '0 Level(0)',
                data: this.fivelevel0,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },

              {
                label:'Beginner(26 >= 1)',
                data: this.fiveBeginner,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label:  'Basic(41 >= 26)',
                data: this.fiveBasic,
                backgroundColor: '#0F9D58',
                borderColor: '#0F9D58',
                fill: true
              },

              {
                label: 'Intermediate(61 >= 41)',
                data: this.fivIntermediate,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true
              },

              {
                label:'Advance(100 >= 61)',
                data: this.fiveAdvance,
                backgroundColor: '#6C3483',
                borderColor: '#6C3483',
                fill: true
              },



            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });
        this.loading = false;
      })
    }
    else {
      console.log("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'");
      
      this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to12("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'").subscribe((data: any) => {
        console.log(data);
        
        data.map((obj) => {
          obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
          obj.IntermediateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
        })

        this.fivstandarddataList = data;
        this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
        this.fivstandarddataList.forEach(X => {
          this.fivstandardlabel.push(X.standard);
          this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
          this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
          this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
          this.fivIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
          this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

        });
        console.log('adv & intrt' + this.fiveBasic);
        if (this.stackchart1) { this.stackchart1.destroy(); }
        this.stackchart1 = new Chart('standardfiv', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.fivstandardlabel,

            datasets: [
              {
                label: '0 Level(0)',
                data: this.fivelevel0,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },

              {
                label: 'Beginner(26 >= 1)',
                data: this.fiveBeginner,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label: 'Basic(41 >= 26)',
                data: this.fiveBasic,
                backgroundColor: '#0F9D58',
                borderColor: '#0F9D58',
                fill: true
              },

              {
                label: 'Intermediate(61 >= 41)',
                data: this.fivIntermediate,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true
              },

              {
                label: 'Advance(100 >= 61)',
                data: this.fiveAdvance,
                backgroundColor: '#6C3483',
                borderColor: '#6C3483',
                fill: true
              },



            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });
        this.loading = false;
      })
    }


  }

  ngOnInit(): void {
  }

  FilterExam(examtype) {
    this.loading = true;
    this.Examtypeval = examtype;
    this.totboys = 0;
    this.totgirls = 0;
    this.alltotstudent = 0;
    this.totapercent = 0;
    this.totbpercent = 0;
    this.totcpercent = 0;
    let totalstudentcont

    this.biglevel = [];
    this.basiclevel = [];
    this.intlevel = [];
    this.advlevel = [];
    this.standardlbl = [];
    this.standardlabel1 = [];
    this.level01 = [];
    this.Beginner1 = [];
    this.Basic1 = [];
    this.Intermediate1 = [];
    this.Advance1 = [];

    this.onefourstandardlabel = [];
    this.fivstandardlabel = [];
    this.level0 = [];
    this.Beginner = [];
    this.Basic = [];
    this.fivlevel0 = [];
    this.fivBeginner = [];
    this.fivBasic = [];
    this.fiveIntermediate = [];
    this.Advance = [];
    this.mathslevel0 = [];
    this.mathsBeginner = [];
    this.mathsBasic = [];
    this.fivelevel0 = [];
    this.fiveBeginner = [];
    this.fiveBasic = [];
    this.fiveAdvance = [];


    this.service.GetCALAssessmentGraphLevel("where centerpartner.partnerid= '" + this.UserId + "' and calschoolassessment.exam='Final Exam'").subscribe((data: any) => {
      this.GetCALAssessmentGraphLevel = data;
      data.forEach(y => {
        this.standardlbl.push('STD ' + y.standard);
        this.biglevel.push(y.Beg);
        this.basiclevel.push(y.Basic);

      })
      if (this.groupchart) { this.groupchart.destroy(); }
      this.groupchart = new Chart('bar-chart-grouped', {

        type: 'bar',
        data: {
          labels: this.standardlbl,
          datasets: [

            {
              label: "Basic Level",
              backgroundColor: '#4885ed',
              borderColor: '#4885ed',
              data: this.basiclevel
            },
            {
              label: "Beginner Level",
              backgroundColor: '#8a2be2',
              borderColor: '#8a2be2',
              data: this.biglevel
            },
          ]
        },
        options: {
          title: {
            display: true,
            text: 'Assessment Levelwise '
          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] != 0;
              },
              align: 'top',
              anchor: 'end',
              color: 'black',
              font: {
                size: 8,
              }
            }
          }
        }
      })
    })

    this.service.GetCALFinalAssessmentLevelStandardPartnerwise("where CALAssessmentLevel.partner='" + this.UserId + "' and CALAssessmentLevel.examtype='Final Exam'").subscribe((data: any) => {
      var graph6 = document.getElementById("graph6");
      if (data.length != 0) {
        graph6.style.display = "block";
        data.sort((a, b) => (a.standard - b.standard));
        data.map((obj) => {
          obj.level0percentage = ((obj.lvel0 * 100) / obj.totalstudent).toFixed(2);
          obj.BeginnerLevelpercentage = ((obj.level1 * 100) / obj.totalstudent).toFixed(2);
          obj.BasicLevelpercentage = ((obj.level2 * 100) / obj.totalstudent).toFixed(2);
          obj.IntermediateLevelpercentage = ((obj.level3 * 100) / obj.totalstudent).toFixed(2);
          obj.AdvanceLevelpercentage = ((obj.level4 * 100) / obj.totalstudent).toFixed(2);
        });

        this.GetCALAssessmentGraphCountFinalList = data;

        data.forEach(X => {
          this.standardlabel1.push('STD ' + X.standard);
          this.level01.push(((X.lvel0 * 100) / X.totalstudent).toFixed(2));
          this.Beginner1.push(((X.level1 * 100) / X.totalstudent).toFixed(2));
          this.Basic1.push(((X.level2 * 100) / X.totalstudent).toFixed(2));
          this.Intermediate1.push(((X.level3 * 100) / X.totalstudent).toFixed(2));
          this.Advance1.push(((X.level4 * 100) / X.totalstudent).toFixed(2));
        });
        if (this.stackchart4) { this.stackchart4.destroy(); }
        this.stackchart4 = new Chart('standard', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.standardlabel1,
            datasets: [
              {
                label: 'Advance Level',
                data: this.Advance1,
                backgroundColor: '#3cba54',
                borderColor: '#3cba54',
                fill: true
              },
              {
                label: 'Intermediate Level',
                data: this.Intermediate1,
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                fill: true
              },
              {
                label: 'Basic Level',
                data: this.Basic1,
                backgroundColor: '#4885ed',
                borderColor: '#4885ed',
                fill: true
              },
              {
                label: 'Beginner Level',
                data: this.Beginner1,
                backgroundColor: '#8a2be2',
                borderColor: '#8a2be2',
                fill: true
              },

              {
                label: '0 Level',
                data: this.level01,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },


            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });
      }
      else {
        graph6.style.display = "none";

      }
    })

    this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "'and exam='" + examtype + "'").subscribe((data: any) => {
      this.GetCALAssessmentGraphCountList = data;

      this.value = this.GetCALAssessmentGraphCountList;
      for (let i = 0; i <= this.GetCALAssessmentGraphCountList.length; i++) {
        this.totboys += this.value[i].boys;
        this.totgirls += this.value[i].girls;
        this.alltotstudent += this.value[i].totstudent;
        this.totapercent += this.value[i].apercent / this.value.length;
        this.totbpercent += this.value[i].bpercent / this.value.length;
        this.totcpercent += this.value[i].cpercent / this.value.length;
      }
    })

    this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "'and exam='" + examtype + "'").subscribe((data: any) => {

      this.standardlabel = new Array();
      this.Agrade = new Array();
      this.Bgrade = new Array();
      this.Cgrade = new Array();


      data.forEach(y => {
        if (y.A != 0 || y.B != 0 || y.C != 0) {
          totalstudentcont = y.A + y.B + y.C
          this.standardlabel.push('STD ' + y.standard);
          this.Agrade.push(((y.A * 100) / totalstudentcont).toFixed(2));
          this.Bgrade.push(((y.B * 100) / totalstudentcont).toFixed(2));
          this.Cgrade.push(((y.C * 100) / totalstudentcont).toFixed(2));

        }


      });
      if (this.stackchart) { this.stackchart.destroy(); }
      this.stackchart = new Chart('stackcanvas', {
        type: 'bar',
        data: {
          labels: this.standardlabel,

          datasets: [


            {
              label: 'A Grade (100 >= 76)',
              data: this.Agrade,
              backgroundColor: '#4285F4',
              borderColor: '#4285F4',
              fill: true,

            },

            {
              label: 'B Grade (75  >= 51)',
              data: this.Bgrade,
              backgroundColor: '#F4B400',
              borderColor: '#F4B400',
              fill: true
            },

            {
              label: 'C Grade (50  >= 35)',
              data: this.Cgrade,
              backgroundColor: '#51B8CD',
              borderColor: '#51B8CD',
              fill: true
            },



          ],

        },
        options: {


          legend: {
            display: true,
            position: 'bottom',

          },

          scales: {
            xAxes: [{

              display: true,
              stacked: true,
              distribution: 'series',
              scaleLabel: {
                display: true,
                labelString: 'Standard',

              }

            }],
            yAxes: [{
              display: true,
              stacked: true,

              ticks: {
                beginAtZero: true,
                stepSize: 10,
                max: 100,
                callback: function (value) {
                  return value + "%"
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Percentage',

              }


            }],

          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'white'
            }
          }
        }
      });
      this.loading = false;

    })

    if (this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') {
      this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to122022("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
        data.map((obj) => {
          obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
          obj.IntermidiateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);

        })

        this.fivstandarddataList = data;
        this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
        data.forEach(X => {
          this.fivstandardlabel.push(X.standard);
          this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
          this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
          this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
          this.fiveIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
          this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

        });

        if (this.stackchart1) { this.stackchart1.destroy(); }
        var graph5 = document.getElementById("graph5");
        if (data.length != 0) {
          this.stackchart1 = new Chart('standardfiv', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.fivstandardlabel,

              datasets: [
                {
                  label: '0 Level(0)',
                  data: this.fivelevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Beginner(26 >= 1)',
                  data: this.fiveBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label:  'Basic(41 >= 26)',
                  data: this.fiveBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },

                {
                  label:'Intermediate(61 >= 41)',
                  data: this.fiveIntermediate,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },

                {
                  label:'Advance(100 >= 61)',
                  data: this.fiveAdvance,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },


              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',
                  }
                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
        }
        else {
          graph5.style.display = "none";
        }
        this.loading = false;

      })
    }
    else {
      console.log("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'");
      this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to12("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'").subscribe((data: any) => {
        console.log(data);

        data.map((obj) => {
          obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
          obj.IntermidiateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);

        })

        this.fivstandarddataList = data;
        this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
        data.forEach(X => {
          this.fivstandardlabel.push(X.standard);
          this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
          this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
          this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
          this.fiveIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
          this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

        });

        if (this.stackchart1) { this.stackchart1.destroy(); }
        var graph5 = document.getElementById("graph5");
        if (data.length != 0) {
          this.stackchart1 = new Chart('standardfiv', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.fivstandardlabel,

              datasets: [
                {
                  label:'0 Level(0)',
                  data: this.fivelevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Beginner(26 >= 1)',
                  data: this.fiveBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Basic(41 >= 26)',
                  data: this.fiveBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },

                {
                  label: 'Intermediate(61 >= 41)',
                  data: this.fiveIntermediate,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },

                {
                  label: 'Advance(100 >= 61)',
                  data: this.fiveAdvance,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },


              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',
                  }
                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
        }
        else {
          graph5.style.display = "none";
        }
        this.loading = false;

      })
    }
  }


}