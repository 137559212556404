import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-idievalution',
  templateUrl: './idievalution.component.html',
  styleUrls: ['./idievalution.component.css']
})
export class IdievalutionComponent implements OnInit {
  UserId;
  pgid;
  IDIAssessmentGraphCountList;
  course='Financial Literacy Skills';
  value;
  tota = 0;
  totb = 0;
  percenta;
  courselabel = new Array();
  Agrade = new Array();
  Bgrade = new Array();
  Cgrade = new Array();
  stackchart: any;
  loading = true;
  financialyear;
  constructor(private service: MasterService,) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.financialyear = localStorage.getItem('key');

    if (this.financialyear == "2023-2024" || this.financialyear == '2024-2025') {
      this.service.GetIDIAssessmentGraphCount("where partnerid= '"+this.UserId+"'").subscribe((data: any) => { 
        this.IDIAssessmentGraphCountList = data;
        let totalgradecount;
        data.forEach(y => {
          if(y.A!=0 || y.B!=0 || y.C!=0 ){
            totalgradecount = y.A+y.B+y.C            
            this.courselabel.push(y.coursename);
            this.Agrade.push(((y.A*100)/totalgradecount).toFixed(2));
            this.Bgrade.push(((y.B*100)/totalgradecount).toFixed(2));
            this.Cgrade.push(((y.C*100)/totalgradecount).toFixed(2));
        
          }
  
        });
        this.stackchart = new Chart('stackcanvas', {
          type: 'bar',
          data: {
            labels: this.courselabel,
      
            datasets: [
              {
                label: 'A Grade (100 >= 76)',
                data: this.Agrade,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true,
      
              },
      
              {
                label: 'B Grade (75  >= 51)',
                data: this.Bgrade,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },
      
              {
                label: 'C Grade (50  >= 35)',
                data: this.Cgrade,
                backgroundColor: '#51B8CD',
                borderColor: '#51B8CD',
                fill: true
              },
            ],
      
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
      
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                labelString: 'Course',
    
              }
      
              }],
              yAxes: [{
                display: true,
                stacked: true,
              
                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function(value) {
                      return value + "%"
                  }
                },
                scaleLabel: {
                    display: true,
                  labelString: 'Percentage',
      
                }
      
      
              }],
      
            },
            plugins: {
              datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex]>= 1; // or >= 1 or ...
               },
                align: 'center',
                anchor: 'center',
                color : 'white'
              }
            }
          }
        });
        this.loading = false;
        });
    } else if(this.financialyear == "2017-2018" || this.financialyear == "2018-2019" || this.financialyear == "2019-2020" || this.financialyear == "2020-2021" || this.financialyear == "2021-2022"|| this.financialyear == "2022-2023"){
      this.service.OldPartnerIDIAssessmentCount(this.UserId,this.financialyear,this.pgid).subscribe((data: any) => { 
        this.IDIAssessmentGraphCountList = data;
        console.log(this.IDIAssessmentGraphCountList);
        
        let totalgradecount;
        data.forEach(y => {
          if(y.A!=0 || y.B!=0 || y.C!=0 ){
            totalgradecount = y.A+y.B+y.C            
            this.courselabel.push(y.coursename);
            this.Agrade.push(((y.A*100)/totalgradecount).toFixed(2));
            this.Bgrade.push(((y.B*100)/totalgradecount).toFixed(2));
            this.Cgrade.push(((y.C*100)/totalgradecount).toFixed(2));
        
          }
  
        });
        this.stackchart = new Chart('stackcanvas', {
          type: 'bar',
          data: {
            labels: this.courselabel,
      
            datasets: [
              {
                label: 'A Grade (100 >= 76)',
                data: this.Agrade,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true,
      
              },
      
              {
                label: 'B Grade (75  >= 51)',
                data: this.Bgrade,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },
      
              {
                label: 'C Grade (50  >= 35)',
                data: this.Cgrade,
                backgroundColor: '#51B8CD',
                borderColor: '#51B8CD',
                fill: true
              },
            ],
      
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
      
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                labelString: 'Course',
    
              }
      
              }],
              yAxes: [{
                display: true,
                stacked: true,
              
                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function(value) {
                      return value + "%"
                  }
                },
                scaleLabel: {
                    display: true,
                  labelString: 'Percentage',
      
                }
      
      
              }],
      
            },
            plugins: {
              datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex]>= 1; // or >= 1 or ...
               },
                align: 'center',
                anchor: 'center',
                color : 'white'
              }
            }
          }
        });
        this.loading = false;
        });
    }

   

   }

  ngOnInit(): void {
  }

}
