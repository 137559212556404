import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-endlinekeralastd6',
  templateUrl: './endlinekeralastd6.component.html',
  styleUrls: ['./endlinekeralastd6.component.css']
})
export class Endlinekeralastd6Component implements OnChanges {

  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  @Input('City') City;
  @Input('Semester') Semester;
  loading = false;
  Partnerid;
  pgid;
  selected;

  EndlineStandard: any;
  BaselineStandard: any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  c5 = new Array();
  compentencies;
  Endlinestd5;
  Endlinestd5count=0;
  totapp;

  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }
  
  ngOnChanges() {
    if (this.City == 'Cochin' && this.Examtype == 'PracticalEndline') {
      if(this.Semester == 'sem 1'){
        this.service.GetSahyogiEvalCompetencyCitywise("where city = 'Cochin' and examtype = 'Endline' and standard = '6' and semester = '"+this.Semester+"' and academicyear = '" + this.selected + "'").subscribe((data: any) => {
          if (this.EndlineStandard) { this.EndlineStandard.destroy(); }
          if (this.BaselineStandard) { this.BaselineStandard.destroy();}
          this.totapp = data[0].totalappeared;
          data.map((obj) => {
            obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
            obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
            obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
            obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
            obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
          })
          this.Endlinestd5 = data;
          this.Endlinestd5count = data.length;
          this.loading = false;
          this.compentencies = new Array();
          data.forEach(y => {
            this.compentencies.push(y.C1);
            this.compentencies.push(y.C2);
            this.compentencies.push(y.C3);
            this.compentencies.push(y.C4);
            this.compentencies.push(y.C5);
          })
  
          var graph = document.getElementById("graph16");
          if (data.length != 0) {
            graph.style.display = "block";
            this.EndlineStandard = new Chart('Standard16', {
              type: 'bar',
              data: {
                labels: ['Simple Documentation', 'Image variation','Map Reading','Libra office word','Draw application'],
  
                datasets: [
                  {
                    label: "Compentencies",
                    backgroundColor: '#f4c20d',
                    borderColor: '#f4c20d',
                    data: this.compentencies
                  },
                ]
              },
              options: {
                scales: {
                  xAxes: [{
                    display: true,
                    stacked: true,
                    distribution: 'series',
                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: "Count",
                    }
                  }],
                },
                title: {
                  display: true,
                  text: this.Examtype + 'Standard' + this.Standard
                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] >= 1;
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'black',
                    font: {
                      size: 14,
                    }
                  }
                }
              }
            })
          }
          else {
            graph.style.display = "none";
  
          }
          this.loading = false;
        })
      }
      else if(this.Semester == 'sem 2'){
        this.service.GetSahyogiEvalCompetencyCitywise("where city = 'Cochin' and examtype = 'Endline' and standard = '6' and semester = '"+this.Semester+"' and academicyear = '" + this.selected + "'").subscribe((data: any) => {
          if (this.EndlineStandard) { this.EndlineStandard.destroy(); }
          if (this.BaselineStandard) { this.BaselineStandard.destroy();}
          this.totapp = data[0].totalappeared;
          data.map((obj) => {
            obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
            obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
            obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
            obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
            obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
            obj.C6 = ((data[0].c6 / this.totapp) * 100).toFixed(2);
          })
          this.Endlinestd5 = data;
          this.Endlinestd5count = data.length;
          this.loading = false;
          this.compentencies = new Array();
          data.forEach(y => {
            this.compentencies.push(y.C1);
            this.compentencies.push(y.C2);
            this.compentencies.push(y.C3);
            this.compentencies.push(y.C4);
            this.compentencies.push(y.C5);
            this.compentencies.push(y.C6);
          })
  
          var graph = document.getElementById("graph16");
          if (data.length != 0) {
            graph.style.display = "block";
            this.EndlineStandard = new Chart('Standard16', {
              type: 'bar',
              data: {
                labels: ['Geogebra', 'Searching Information','Observation & Conclusion','Stellarium','Geogebra','Search Engine'],
  
                datasets: [
                  {
                    label: "Compentencies",
                    backgroundColor: '#f4c20d',
                    borderColor: '#f4c20d',
                    data: this.compentencies
                  },
                ]
              },
              options: {
                scales: {
                  xAxes: [{
                    display: true,
                    stacked: true,
                    distribution: 'series',
                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: "Count",
                    }
                  }],
                },
                title: {
                  display: true,
                  text: this.Examtype + 'Standard' + this.Standard
                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] >= 1;
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'black',
                    font: {
                      size: 14,
                    }
                  }
                }
              }
            })
          }
          else {
            graph.style.display = "none";
  
          }
          this.loading = false;
        })
      }

    }
    else {
      this.Endlinestd5 = [];
      this.Endlinestd5count = 0;
      this.loading = false;

      if(this.Semester == 'sem 1'){
        this.service.GetSahyogiEvalCompetencyCitywise("where city = 'Cochin' and examtype = 'Baseline' and standard = '6' and academicyear = '" + this.selected + "' and semester = '"+this.Semester+"'").subscribe((data: any) => {
          if (this.EndlineStandard) { this.EndlineStandard.destroy(); }
          if (this.BaselineStandard) { this.BaselineStandard.destroy();}
          this.totapp = data[0].totalappeared;
          data.map((obj) => {
            obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
            obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
            obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
            obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
            obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
          })
          this.Endlinestd5 = data;
          this.Endlinestd5count = data.length;
          this.loading = false;
          this.compentencies = new Array();
          data.forEach(y => {
            this.compentencies.push(y.C1);
            this.compentencies.push(y.C2);
            this.compentencies.push(y.C3);
            this.compentencies.push(y.C4);
            this.compentencies.push(y.C5);
          })
  
          var graph = document.getElementById("graph16");
          if (data.length != 0) {
            graph.style.display = "block";
            this.BaselineStandard = new Chart('Standard16', {
              type: 'bar',
              data: {
                labels: ['Simple Documentation', 'Image variation','Map Reading','Libra office word','Draw application'],
  
                datasets: [
                  {
                    label: "Compentencies",
                    backgroundColor: '#f4c20d',
                    borderColor: '#f4c20d',
                    data: this.compentencies
                  },
                ]
              },
              options: {
                scales: {
                  xAxes: [{
                    display: true,
                    stacked: true,
                    distribution: 'series',
                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: "Count",
                    }
                  }],
                },
                title: {
                  display: true,
                  text: this.Examtype + 'Standard' + this.Standard
                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] >= 1;
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'black',
                    font: {
                      size: 14,
                    }
                  }
                }
              }
            })
          }
          else {
            graph.style.display = "none";
  
          }
          this.loading = false;
        })
      }
      else if(this.Semester == 'sem 2'){
        this.service.GetSahyogiEvalCompetencyCitywise("where city = 'Cochin' and examtype = 'Baseline' and standard = '6' and academicyear = '" + this.selected + "' and semester = '"+this.Semester+"'").subscribe((data: any) => {
          if (this.EndlineStandard) { this.EndlineStandard.destroy(); }
          if (this.BaselineStandard) { this.BaselineStandard.destroy();}
          this.totapp = data[0].totalappeared;
          data.map((obj) => {
            obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
            obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
            obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
            obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
            obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
            obj.C6 = ((data[0].c6 / this.totapp) * 100).toFixed(2);
          })
          this.Endlinestd5 = data;
          this.Endlinestd5count = data.length;
          this.loading = false;
          this.compentencies = new Array();
          data.forEach(y => {
            this.compentencies.push(y.C1);
            this.compentencies.push(y.C2);
            this.compentencies.push(y.C3);
            this.compentencies.push(y.C4);
            this.compentencies.push(y.C5);
            this.compentencies.push(y.C6);
          })
  
          var graph = document.getElementById("graph16");
          if (data.length != 0) {
            graph.style.display = "block";
            this.BaselineStandard = new Chart('Standard16', {
              type: 'bar',
              data: {
                labels: ['Geogebra', 'Searching Information','Observation & Conclusion','Stellarium','Geogebra','Search Engine'],
  
                datasets: [
                  {
                    label: "Compentencies",
                    backgroundColor: '#f4c20d',
                    borderColor: '#f4c20d',
                    data: this.compentencies
                  },
                ]
              },
              options: {
                scales: {
                  xAxes: [{
                    display: true,
                    stacked: true,
                    distribution: 'series',
                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: "Count",
                    }
                  }],
                },
                title: {
                  display: true,
                  text: this.Examtype + 'Standard' + this.Standard
                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] >= 1;
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'black',
                    font: {
                      size: 14,
                    }
                  }
                }
              }
            })
          }
          else {
            graph.style.display = "none";
  
          }
          this.loading = false;
        })
      }

    }
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard + "th Standard Endline Summary.png";
  }

}



