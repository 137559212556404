import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-assessmentlevelwise',
  templateUrl: './assessmentlevelwise.component.html',
  styleUrls: ['./assessmentlevelwise.component.css']
})
export class AssessmentlevelwiseComponent implements OnInit {
  UserId;
  pgid;
  standardlabel = new Array();
  Agrade = new Array();
  Bgrade = new Array();
  Cgrade = new Array();
  stackchart: any;
  loading = false;
  totboys = 0;
  totgirls = 0;
  alltotstudent = 0;
  totapercent = 0;
  totbpercent = 0;
  totcpercent = 0;
  private value;

  GetCALAssessmentGraphCountList;
  GetCALAssessmentGraphLevel;
  biglevel = new Array();
  basiclevel = new Array();
  intlevel = new Array();
  advlevel = new Array();
  standardlbl = new Array();
  groupchart: any
  groupchart1: any
  stdlbl = new Array();
  apercent = new Array();
  bpercent = new Array();
  cpercent = new Array();
  dpercent = new Array();
  GetCALAssessmentGraphCountFinalList
  Examtype;
  standardlabel1 = new Array();
  level01 = new Array();
  Beginner1 = new Array();
  Basic1 = new Array();
  Intermediate1 = new Array();
  fiveIntermediate = new Array();
  Advance1 = new Array();
  stackchart4;
  Examtypeval = 'Baseline';
  selectfiyear;

  standarddataList: any = [];
  englishAserDataList = [];
  englishAserDataListCount;
  EngAserGraph;

  onefourstandardlabel: any = new Array();
  fivstandardlabel = new Array();
  level0 = new Array();
  Beginner = new Array();
  Basic = new Array();
  fivelevel0 = new Array();
  fiveBeginner = new Array();
  fiveBasic = new Array();
  fivIntermediate = new Array();
  fiveAdvance = new Array();
  fivlevel0 = new Array();
  fivBeginner = new Array();
  fivBasic = new Array();

  Advance = new Array();
  mathslevel0 = new Array();
  mathsBeginner = new Array();
  mathsBasic = new Array();
  mathsIntermediate = new Array();
  mathsAdvanced = new Array();
  stackchart1: any;
  stackchart2: any;
  stackchart3: any;
  stackchart31: any;
  fivstandarddataList;
  absent = new Array();
  mathabsent = new Array();

  bonusList = [];
  q1 = new Array();
  q2 = new Array();
  q3 = new Array();
  q4 = new Array();
  bonusGraph;
  bonusListCount: any;
  standarddataListCount: any;

  engStandard = new Array();
  engLevel0 = new Array();
  engLetter = new Array();
  engWord = new Array();
  engSentence = new Array();
  engPara = new Array();
  engStory = new Array();

  constructor(private service: MasterService,) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    console.log(localStorage);


    if (this.selectfiyear == '2020-2021') {
      this.Examtype = 'Beginner Level'
    }

    else if (this.selectfiyear == '2021-2022') {
      this.Examtype = 'Baseline'
      // this.Examtype = 'Endline'
    }

    else if (this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') {

      if (this.pgid == 'P9') {
        this.Examtype = 'Midline'
      }
      else {

        this.Examtype = 'Baseline'
        // this.Examtype = 'Aser'
        // this.Examtype = 'AserMidline'

      }

    }

    if (this.pgid != 'P9') {

      if (this.pgid != 'P9') {
        this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "' and exam='" + this.Examtype + "'").subscribe((data: any) => {
          this.GetCALAssessmentGraphCountList = data;
          this.value = this.GetCALAssessmentGraphCountList;
          for (let i = 0; i <= this.GetCALAssessmentGraphCountList.length; i++) {
            this.totboys += this.value[i].boys;
            this.totgirls += this.value[i].girls;
            this.alltotstudent += this.value[i].totstudent;
            this.totapercent += this.value[i].apercent / this.value.length;
            this.totbpercent += this.value[i].bpercent / this.value.length;
            this.totcpercent += this.value[i].cpercent / this.value.length;
            // break;
          }


        })
        let totalstudentcont
        this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "'and exam='" + this.Examtype + "'").subscribe((data: any) => {
          data.forEach(y => {
            if (y.A != 0 || y.B != 0 || y.C != 0) {
              totalstudentcont = y.A + y.B + y.C
              this.standardlabel.push('STD ' + y.standard);
              this.Agrade.push(((y.A * 100) / totalstudentcont).toFixed(2));
              this.Bgrade.push(((y.B * 100) / totalstudentcont).toFixed(2));
              this.Cgrade.push(((y.C * 100) / totalstudentcont).toFixed(2));
            }


          });
          if (this.stackchart) { this.stackchart.destroy(); }
          this.stackchart = new Chart('stackcanvas', {
            type: 'bar',
            data: {
              labels: this.standardlabel,

              datasets: [
                {
                  label: 'A Grade (100 >= 76)',
                  data: this.Agrade,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true,

                },

                {
                  label: 'B Grade (75  >= 51)',
                  data: this.Bgrade,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'C Grade (50  >= 35)',
                  data: this.Cgrade,
                  backgroundColor: '#51B8CD',
                  borderColor: '#51B8CD',
                  fill: true
                },



              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
          this.loading = false;
        })
console.log("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'");

        this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi1to4("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'").subscribe((data: any) => {
          data.map((obj) => {
            obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.mathlevel0percentage = ((obj.mathlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.mathBeginnerLevelpercentage = ((obj.mathbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.mathBasicLevelpercentage = ((obj.mathbasic * 100) / obj.totalstudent).toFixed(2);

          });

          this.standarddataList = data;
          this.standarddataList = this.standarddataList.sort((a, b) => (a.standard - b.standard));
          data.forEach(X => {
            this.onefourstandardlabel.push(X.standard);
            this.level0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.Beginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.Basic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.mathslevel0.push(((X.mathlevel0 * 100) / X.totalstudent).toFixed(2));
            this.mathsBeginner.push(((X.mathbeginner * 100) / X.totalstudent).toFixed(2));
            this.mathsBasic.push(((X.mathbasic * 100) / X.totalstudent).toFixed(2));

          });
          if (this.stackchart2) { this.stackchart2.destroy(); }
          this.stackchart2 = new Chart('standardlang', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.onefourstandardlabel,

              datasets: [
                {
                  label: '0 Level',
                  data: this.level0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Beginner',
                  data: this.Beginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Basic',
                  data: this.Basic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });

          if (this.stackchart3) { this.stackchart3.destroy(); }
          this.stackchart3 = new Chart('standardmaths', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.onefourstandardlabel,

              datasets: [
                {
                  label: '0 Level',
                  data: this.mathslevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Beginner',
                  data: this.mathsBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Basic',
                  data: this.mathsBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
          this.loading = false;
        })
      }

      if (this.pgid != 'P9' && (this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') && (this.Examtype == 'Baseline' || this.Examtype == 'Midline' || this.Examtype == 'Endline')) {

        this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to122022("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and academicyear = '" + this.selectfiyear + "'").subscribe((data: any) => {

          data.map((obj) => {
            obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.IntermediateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
          })

          this.fivstandarddataList = data;

          this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
          this.fivstandarddataList.forEach(X => {
            this.fivstandardlabel.push(X.standard);
            this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.fivIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
            this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

          });
          if (this.stackchart1) { this.stackchart1.destroy(); }
          this.stackchart1 = new Chart('standardfiv', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.fivstandardlabel,

              datasets: [
                {
                  label:'0 Level(0)',
                  data: this.fivelevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Beginner(26 >= 1)',
                  data: this.fiveBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Basic(41 >= 26)',
                  data: this.fiveBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },

                {
                  label: 'Intermediate(61 >= 41)',
                  data: this.fivIntermediate,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },

                {
                  label: 'Advance(100 >= 61)',
                  data: this.fiveAdvance,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },



              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
          this.loading = false;
        })
      }
      else if ((this.pgid != 'P9' && this.pgid != 'P22') && (this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024') && (this.Examtype == 'Aser' || this.Examtype == 'AserMidline' || this.Examtype == 'AserEndline')) {

        this.service.GetCALAssessmentLevelStdGraph2022sayhogi1to4("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and academicyear = '" + this.selectfiyear + "'").subscribe((data: any) => {

          data.map((obj) => {
            obj.langlevel0 = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.langbeginner = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.langbasic = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.langintermediate = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.langadvance = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
            obj.absent = ((obj.absent * 100) / obj.totalstudent).toFixed(2);
            obj.mathlevel0 = ((obj.mathlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.mathbeginner = ((obj.mathbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.mathbasic = ((obj.mathbasic * 100) / obj.totalstudent).toFixed(2);
            obj.mathintermediate = ((obj.mathintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.mathadvance = ((obj.mathadvance * 100) / obj.totalstudent).toFixed(2);
            obj.mathabsent = ((obj.mathabsent * 100) / obj.totalstudent).toFixed(2);

          });

          this.standarddataList = data;
          this.standarddataList = this.standarddataList.sort((a, b) => (a.standard - b.standard));
          data.forEach(X => {
            this.onefourstandardlabel.push(X.standard);
            this.level0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.Beginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.Basic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.Intermediate1.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
            this.Advance1.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));
            this.absent.push(((X.absent * 100) / X.totalstudent).toFixed(2));
            this.mathslevel0.push(((X.mathlevel0 * 100) / X.totalstudent).toFixed(2));
            this.mathsBeginner.push(((X.mathbeginner * 100) / X.totalstudent).toFixed(2));
            this.mathsBasic.push(((X.mathbasic * 100) / X.totalstudent).toFixed(2));
            this.mathsIntermediate.push(((X.mathintermediate * 100) / X.totalstudent).toFixed(2));
            this.mathsAdvanced.push(((X.mathadvance * 100) / X.totalstudent).toFixed(2));
            this.mathabsent.push(((X.mathabsent * 100) / X.totalstudent).toFixed(2));

          });
          if (this.stackchart2) {
            this.stackchart2.destroy();
          }
          this.stackchart2 = new Chart('standardlang', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.onefourstandardlabel,

              datasets: [
                {
                  label: '0 Level',
                  data: this.level0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Letter',
                  data: this.Beginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Word',
                  data: this.Basic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Paragraph',
                  data: this.Intermediate1,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },
                {
                  label: 'Story',
                  data: this.Advance1,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },
                {
                  label: 'Absent',
                  data: this.absent,
                  backgroundColor: '#808080',
                  borderColor: '#808080',
                  fill: true
                },

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });

          if (this.stackchart31) { this.stackchart31.destroy(); }
          this.stackchart31 = new Chart('standardmaths', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.onefourstandardlabel,

              datasets: [
                {
                  label: '0 Level',
                  data: this.mathslevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: '1 Digit Number',
                  data: this.mathsBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: '2 Digit Number',
                  data: this.mathsBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Subtraction',
                  data: this.mathsIntermediate,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Division',
                  data: this.mathsAdvanced,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Absent',
                  data: this.mathabsent,
                  backgroundColor: '#808080',
                  borderColor: '#808080',
                  fill: true
                },

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
          this.loading = false;
        })
      }
      else if (this.pgid == 'P22' && (this.Examtype == 'AserBaseline' || this.Examtype == 'AserMidline' || this.Examtype == 'AserEndline')) {
        let examtype;
        if (this.Examtype == 'AserBaseline') {
          examtype = 'Baseline';
        }
        else if (this.Examtype == 'AserMidline') {
          examtype = 'Midline';
        }
        else if (this.Examtype == 'AserEndline') {
          examtype = 'Endline';
        }

        this.service.GetEnglishAserAssessmentLevelStdGraphsayhogi1to12("where partnerid='" + this.UserId + "' and examtype='" + examtype + "' and EnglishAserAssessment.pgid = '" + this.pgid + "' and year = '" + this.selectfiyear + "'").subscribe((data: any) => {

          this.englishAserDataList = data;
          this.englishAserDataListCount = data.length;

          this.englishAserDataList.map((obj) => {
            obj.langlevel0 = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.letter = ((obj.letter * 100) / obj.totalstudent).toFixed(2);
            obj.word = ((obj.word * 100) / obj.totalstudent).toFixed(2);
            obj.sentence = ((obj.sentence * 100) / obj.totalstudent).toFixed(2);
            obj.paragraph = ((obj.paragraph * 100) / obj.totalstudent).toFixed(2);
            obj.story = ((obj.story * 100) / obj.totalstudent).toFixed(2);
          });

          data = data.sort((a, b) => (a.standard - b.standard));
          data.forEach(y => {
            this.engStandard.push(y.standard);
            this.engLevel0.push(y.langlevel0);
            this.engLetter.push(y.letter);
            this.engWord.push(y.word);
            this.engSentence.push(y.sentence);
            this.engPara.push(y.paragraph);
            this.engStory.push(y.story);
          });
          if (this.EngAserGraph) { this.EngAserGraph.destroy(); }
          this.EngAserGraph = new Chart('EngAserGraph', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.engStandard,

              datasets: [
                {
                  label: '0 Level',
                  data: this.engLevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Letter',
                  data: this.engLetter,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Word',
                  data: this.engWord,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Sentence',
                  data: this.engSentence,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Paragraph',
                  data: this.engPara,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },
                {
                  label: 'Story',
                  data: this.engStory,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                }

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });

          this.loading = false;
        });

      }
      else {
        console.log(this.UserId);
        console.log(this.Examtype);

        this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to12("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'").subscribe((data: any) => {
          console.log(data);

          data.map((obj) => {
            obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.IntermediateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
          })

          this.fivstandarddataList = data;
          this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
          this.fivstandarddataList.forEach(X => {
            this.fivstandardlabel.push(X.standard);
            this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.fivIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
            this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

          });

          if (this.stackchart1) { this.stackchart1.destroy(); }
          this.stackchart1 = new Chart('standardfiv', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.fivstandardlabel,

              datasets: [
                {
                  label: '0 Level(0)',
                  data: this.fivelevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Beginner(26 >= 1)',
                  data: this.fiveBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label:  'Basic(41 >= 26)',
                  data: this.fiveBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },

                {
                  label: 'Intermediate(61 >= 41)',
                  data: this.fivIntermediate,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },

                {
                  label: 'Advance(100 >= 61)',
                  data: this.fiveAdvance,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },



              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
          this.loading = false;
        })
      }

    }

    if (this.pgid == 'P9') {

      this.service.GetMyEschoolAssessmentLevelStdGraph2022sayhogi1to4("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'and pgid = '" + this.pgid + "' and academicyear = '" + this.selectfiyear + "'").subscribe((data: any) => {

        this.standarddataList = data;
        this.standarddataListCount = data.length;

        this.standarddataList.map((obj) => {
          obj.langlevel0 = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.langbeginner = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.langbasic = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
          obj.langintermediate = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.langadvance = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
          // obj.absent = ((obj.absent * 100) / obj.totalstudent).toFixed(2);
          obj.mathlevel0 = ((obj.mathlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.mathbeginner = ((obj.mathbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.mathbasic = ((obj.mathbasic * 100) / obj.totalstudent).toFixed(2);
          obj.mathintermediate = ((obj.mathintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.mathadvance = ((obj.mathadvance * 100) / obj.totalstudent).toFixed(2);
          // obj.mathabsent = ((obj.mathabsent * 100) / obj.totalstudent).toFixed(2);

        });

        data = data.sort((a, b) => (a.standard - b.standard));

        data.forEach(y => {
          this.onefourstandardlabel.push(y.standard);
          this.level0.push(y.langlevel0);
          this.Beginner.push(y.langbeginner);
          this.Basic.push(y.langbasic);
          this.Intermediate1.push(y.langintermediate);
          this.Advance1.push(y.langadvance);
          // this.absent.push(y.absent);
          this.mathslevel0.push(y.mathlevel0);
          this.mathsBeginner.push(y.mathbeginner);
          this.mathsBasic.push(y.mathbasic);
          this.mathsIntermediate.push(y.mathintermediate);
          this.mathsAdvanced.push(y.mathadvance);
          // this.mathabsent.push(y.mathabsent);

        });

        this.stackchart2 = new Chart('standardlang', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.onefourstandardlabel,

            datasets: [
              {
                label: '0 Level',
                data: this.level0,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },

              {
                label: 'Letter',
                data: this.Beginner,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label: 'Word',
                data: this.Basic,
                backgroundColor: '#0F9D58',
                borderColor: '#0F9D58',
                fill: true
              },
              {
                label: 'Paragraph',
                data: this.Intermediate1,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true
              },
              {
                label: 'Story',
                data: this.Advance1,
                backgroundColor: '#6C3483',
                borderColor: '#6C3483',
                fill: true
              },
              // {
              //   label: 'Absent',
              //   data: this.absent,
              //   backgroundColor: '#808080',
              //   borderColor: '#808080',
              //   fill: true
              // },

            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });

        this.stackchart31 = new Chart('standardmaths', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.onefourstandardlabel,

            datasets: [
              {
                label: '0 Level',
                data: this.mathslevel0,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },

              {
                label: '1 Digit Number',
                data: this.mathsBeginner,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label: '2 Digit Number',
                data: this.mathsBasic,
                backgroundColor: '#0F9D58',
                borderColor: '#0F9D58',
                fill: true
              },
              {
                label: 'Subtraction',
                data: this.mathsIntermediate,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true
              },
              {
                label: 'Division',
                data: this.mathsAdvanced,
                backgroundColor: '#6C3483',
                borderColor: '#6C3483',
                fill: true
              },
              // {
              //   label: 'Absent',
              //   data: this.mathabsent,
              //   backgroundColor: '#808080',
              //   borderColor: '#808080',
              //   fill: true
              // },

            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }

              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });

        this.loading = false;
      })

      this.service.GetMyESchoolAserPartnerGraph("where MyESchoolAssessmentLevelStudent.partnerid='" + this.UserId + "'and exam='" + this.Examtype + "' and Center.pgid = '" + this.pgid + "' and MyESchoolAssessmentLevelStudent.year = '" + this.selectfiyear + "'").subscribe((data: any) => {

        if (this.bonusGraph) {
          this.bonusGraph.destroy();
        };

        this.q1 = [];
        this.q2 = [];
        this.q3 = [];
        this.q4 = [];
        this.bonusListCount = 0;

        this.bonusList = data;
        this.bonusListCount = data.length;

        if (this.bonusListCount) {

          data.forEach(y => {
            this.standardlabel.push('STD ' + y.standard);
            this.q1.push(((y.q1)).toFixed(2));
            this.q2.push(((y.q2)).toFixed(2));
            this.q3.push(((y.q3)).toFixed(2));
            this.q4.push(((y.q4)).toFixed(2));
          })

          this.bonusGraph = new Chart('bonusgraph', {
            type: 'horizontalBar',
            data: {
              labels: this.standardlabel,
              datasets: [
                {
                  label: 'Q1-Calculating Time',
                  data: this.q1,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true,

                },

                {
                  label: 'Q2-Applying Unitary Method',
                  data: this.q2,
                  backgroundColor: '#3cba54',
                  borderColor: '#3cba54',
                  fill: true,
                },

                {
                  label: 'Q3-Simple operationa Financial Meeting',
                  data: this.q3,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Q4-Computing Discounted Price',
                  data: this.q4,
                  backgroundColor: '#cc0000',
                  borderColor: '#cc0000',
                  fill: true
                },

              ],
            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    // labelString: 'ASER - Bonus', 
                    fontSize: 15,
                  },
                  position: 'bottom',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',
                    fontSize: 15,
                  },

                  ticks: {
                    reverse: true,
                  },

                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white',

                }
              },
              aspectRatio: 3
            }
          });

          this.loading = false;
        }
      })
    }

  }

  ngOnInit(): void {

  }

  FilterExam(examtype) {

    this.loading = true;
    this.Examtypeval = examtype;
    this.totboys = 0;
    this.totgirls = 0;
    this.alltotstudent = 0;
    this.totapercent = 0;
    this.totbpercent = 0;
    this.totcpercent = 0;
    let totalstudentcont

    this.biglevel = [];
    this.basiclevel = [];
    this.intlevel = [];
    this.advlevel = [];
    this.standardlbl = [];
    this.standardlabel1 = [];
    this.level01 = [];
    this.Beginner1 = [];
    this.Basic1 = [];
    this.Intermediate1 = [];
    this.Advance1 = [];
    this.absent = [];

    this.onefourstandardlabel = [];
    this.fivstandardlabel = [];
    this.level0 = [];
    this.Beginner = [];
    this.Basic = [];
    this.fivlevel0 = [];
    this.fivBeginner = [];
    this.fivBasic = [];
    this.fivIntermediate = [];
    this.Advance = [];
    this.mathslevel0 = [];
    this.mathsBeginner = [];
    this.mathsBasic = [];
    this.mathabsent = [];
    this.fivelevel0 = [];
    this.fiveBeginner = [];
    this.fiveBasic = [];
    this.fiveAdvance = [];

    this.engStandard = [];
    this.engLevel0 = [];
    this.engLetter = [];
    this.engWord = [];
    this.engSentence = [];
    this.engPara = [];
    this.engStory = [];
    this.bonusList = [];
    this.standardlabel = [];

    if (this.pgid != 'P9') {

      this.service.GetCALAssessmentGraphLevel("where centerpartner.partnerid= '" + this.UserId + "' and calschoolassessment.exam='Final Exam'").subscribe((data: any) => {

        this.GetCALAssessmentGraphLevel = data;

        data.forEach(y => {
          this.standardlbl.push('STD ' + y.standard);
          this.biglevel.push(y.Beg);
          this.basiclevel.push(y.Basic);
          this.intlevel.push(y.Inte);
          this.advlevel.push(y.Adv);
        })
        if (this.groupchart) { this.groupchart.destroy(); }
        this.groupchart = new Chart('bar-chart-grouped', {

          type: 'bar',
          data: {
            labels: this.standardlbl,
            datasets: [
              {
                label: "Advance Level",
                backgroundColor: '#3cba54',
                borderColor: '#3cba54',
                data: this.advlevel
              },
              {
                label: "Intermediate Level",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.intlevel
              },
              {
                label: "Basic Level",
                backgroundColor: '#4885ed',
                borderColor: '#4885ed',
                data: this.basiclevel
              },
              {
                label: "Beginner Level",
                backgroundColor: '#8a2be2',
                borderColor: '#8a2be2',
                data: this.biglevel
              },
            ]
          },
          options: {
            title: {
              display: true,
              text: 'Assessment Levelwise '
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0;
                },
                align: 'top',
                anchor: 'end',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        })
      })

      this.service.GetCALFinalAssessmentLevelStandardPartnerwise("where CALAssessmentLevel.partner='" + this.UserId + "' and CALAssessmentLevel.examtype='Final Exam'").subscribe((data: any) => {
        var graph6 = document.getElementById("graph6");
        if (data.length != 0) {
          graph6.style.display = "block";
          data.sort((a, b) => (a.standard - b.standard));
          data.map((obj) => {
            obj.level0percentage = ((obj.lvel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.level1 * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.level2 * 100) / obj.totalstudent).toFixed(2);
            obj.IntermediateLevelpercentage = ((obj.level3 * 100) / obj.totalstudent).toFixed(2);
            obj.AdvanceLevelpercentage = ((obj.level4 * 100) / obj.totalstudent).toFixed(2);
          });

          this.GetCALAssessmentGraphCountFinalList = data;

          data.forEach(X => {
            this.standardlabel1.push('STD ' + X.standard);
            this.level01.push(((X.lvel0 * 100) / X.totalstudent).toFixed(2));
            this.Beginner1.push(((X.level1 * 100) / X.totalstudent).toFixed(2));
            this.Basic1.push(((X.level2 * 100) / X.totalstudent).toFixed(2));
            this.Intermediate1.push(((X.level3 * 100) / X.totalstudent).toFixed(2));
            this.Advance1.push(((X.level4 * 100) / X.totalstudent).toFixed(2));
          });
          if (this.stackchart4) { this.stackchart4.destroy(); }
          this.stackchart4 = new Chart('standard', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.standardlabel1,
              datasets: [
                {
                  label: 'Advance Level',
                  data: this.Advance1,
                  backgroundColor: '#3cba54',
                  borderColor: '#3cba54',
                  fill: true
                },
                {
                  label: 'Intermediate Level',
                  data: this.Intermediate1,
                  backgroundColor: '#f4c20d',
                  borderColor: '#f4c20d',
                  fill: true
                },
                {
                  label: 'Basic Level',
                  data: this.Basic1,
                  backgroundColor: '#4885ed',
                  borderColor: '#4885ed',
                  fill: true
                },
                {
                  label: 'Beginner Level',
                  data: this.Beginner1,
                  backgroundColor: '#8a2be2',
                  borderColor: '#8a2be2',
                  fill: true
                },

                {
                  label: '0 Level',
                  data: this.level01,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },


              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
        }
        else {
          graph6.style.display = "none";

        }
      })

      this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "'and exam='" + examtype + "'").subscribe((data: any) => {
        this.GetCALAssessmentGraphCountList = data;

        this.value = this.GetCALAssessmentGraphCountList;
        for (let i = 0; i <= this.GetCALAssessmentGraphCountList.length; i++) {
          this.totboys += this.value[i].boys;
          this.totgirls += this.value[i].girls;
          this.alltotstudent += this.value[i].totstudent;
          this.totapercent += this.value[i].apercent / this.value.length;
          this.totbpercent += this.value[i].bpercent / this.value.length;
          this.totcpercent += this.value[i].cpercent / this.value.length;
        }
      })

      this.service.GetCALAssessmentGraphCount("where partnerid= '" + this.UserId + "'and exam='" + examtype + "'").subscribe((data: any) => {

        this.standardlabel = new Array();
        this.Agrade = new Array();
        this.Bgrade = new Array();
        this.Cgrade = new Array();


        data.forEach(y => {
          if (y.A != 0 || y.B != 0 || y.C != 0) {
            totalstudentcont = y.A + y.B + y.C
            this.standardlabel.push('STD ' + y.standard);
            this.Agrade.push(((y.A * 100) / totalstudentcont).toFixed(2));
            this.Bgrade.push(((y.B * 100) / totalstudentcont).toFixed(2));
            this.Cgrade.push(((y.C * 100) / totalstudentcont).toFixed(2));

          }


        });
        if (this.stackchart) { this.stackchart.destroy(); }
        this.stackchart = new Chart('stackcanvas', {
          type: 'bar',
          data: {
            labels: this.standardlabel,

            datasets: [


              {
                label: 'A Grade (100 >= 76)',
                data: this.Agrade,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true,

              },

              {
                label: 'B Grade (75  >= 51)',
                data: this.Bgrade,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label: 'C Grade (50  >= 35)',
                data: this.Cgrade,
                backgroundColor: '#51B8CD',
                borderColor: '#51B8CD',
                fill: true
              },



            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });
        this.loading = false;

      })

    }

    if (this.pgid != 'P9') {

      if ((this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') && (this.Examtype == 'Aser' || this.Examtype == 'AserMidline' || this.Examtype == 'AserEndline') && this.pgid != 'P9' && this.pgid != 'P22') {
        this.service.GetCALAssessmentLevelStdGraph2022sayhogi1to4("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and academicyear = '" + this.selectfiyear + "'").subscribe((data: any) => {
          data.map((obj) => {
            obj.langlevel0 = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.langbeginner = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.langbasic = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.langintermediate = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.langadvance = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
            obj.absent = ((obj.absent * 100) / obj.totalstudent).toFixed(2);
            obj.mathlevel0 = ((obj.mathlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.mathbeginner = ((obj.mathbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.mathbasic = ((obj.mathbasic * 100) / obj.totalstudent).toFixed(2);
            obj.mathintermediate = ((obj.mathintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.mathadvance = ((obj.mathadvance * 100) / obj.totalstudent).toFixed(2);
            obj.mathabsent = ((obj.mathabsent * 100) / obj.totalstudent).toFixed(2);

          });

          this.standarddataList = data;

          this.standarddataList = this.standarddataList.sort((a, b) => (a.standard - b.standard));
          this.standarddataList.forEach(X => {
            this.onefourstandardlabel.push(X.standard);
            this.level0.push(X.langlevel0);
            this.Beginner.push(X.langbeginner);
            this.Basic.push(X.langbasic);
            this.Intermediate1.push(X.langintermediate);
            this.Advance1.push(X.langadvance);
            this.absent.push(X.absent);
            this.mathslevel0.push(X.mathlevel0);
            this.mathsBeginner.push(X.mathbeginner);
            this.mathsBasic.push(X.mathbasic);
            this.mathsIntermediate.push(X.mathintermediate);
            this.mathsAdvanced.push(X.mathadvance);
            this.mathabsent.push(X.mathabsent);

          });
          // var graph = document.getElementById("graph");
          if (this.stackchart2) {

            this.stackchart2.destroy();
          }
          // graph.style.display = "block";
          this.stackchart2 = new Chart('standardlang', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.onefourstandardlabel,

              datasets: [
                {
                  label: '0 Level',
                  data: this.level0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Letter',
                  data: this.Beginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Word',
                  data: this.Basic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Paragraph',
                  data: this.Intermediate1,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },
                {
                  label: 'Story',
                  data: this.Advance1,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },
                // {
                //   label: 'Absent',
                //   data: this.absent,
                //   backgroundColor: '#808080',
                //   borderColor: '#808080',
                //   fill: true
                // },

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }

          });

          if (this.stackchart3) { this.stackchart3.destroy(); }
          this.stackchart3 = new Chart('standardmaths', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.onefourstandardlabel,

              datasets: [
                {
                  label: '0 Level',
                  data: this.mathslevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: '1 Digit Number',
                  data: this.mathsBeginner,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: '2 Digit Number',
                  data: this.mathsBasic,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Subtraction',
                  data: this.mathsIntermediate,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },
                {
                  label: 'Division',
                  data: this.mathsAdvanced,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                },
                // {
                //   label: 'Absent',
                //   data: this.mathabsent,
                //   backgroundColor: '#808080',
                //   borderColor: '#808080',
                //   fill: true
                // },

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',

                  }

                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });
          this.loading = false;
        })
      }
      else if (this.pgid == 'P22' && (this.Examtype == 'AserBaseline' || this.Examtype == 'AserMidline' || this.Examtype == 'AserEndline')) {

        let examtype;

        if (this.Examtype == 'AserBaseline') {
          examtype = 'Baseline';
        }
        else if (this.Examtype == 'AserMidline') {
          examtype = 'Midline';
        }
        else if (this.Examtype == 'AserEndline') {
          examtype = 'Endline';
        }

        this.service.GetEnglishAserAssessmentLevelStdGraphsayhogi1to12("where partnerid='" + this.UserId + "' and examtype='" + examtype + "' and EnglishAserAssessment.pgid = '" + this.pgid + "' and year = '" + this.selectfiyear + "'").subscribe((data: any) => {

          this.englishAserDataList = data;
          this.englishAserDataListCount = data.length;

          this.englishAserDataList.map((obj) => {
            obj.langlevel0 = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.letter = ((obj.letter * 100) / obj.totalstudent).toFixed(2);
            obj.word = ((obj.word * 100) / obj.totalstudent).toFixed(2);
            obj.sentence = ((obj.sentence * 100) / obj.totalstudent).toFixed(2);
            obj.paragraph = ((obj.paragraph * 100) / obj.totalstudent).toFixed(2);
            obj.story = ((obj.story * 100) / obj.totalstudent).toFixed(2);
          });

          data = data.sort((a, b) => (a.standard - b.standard));
          data.forEach(y => {
            this.engStandard.push(y.standard);
            this.engLevel0.push(y.langlevel0);
            this.engLetter.push(y.letter);
            this.engWord.push(y.word);
            this.engSentence.push(y.sentence);
            this.engPara.push(y.paragraph);
            this.engStory.push(y.story);
          });
          if (this.EngAserGraph) { this.EngAserGraph.destroy(); }
          this.EngAserGraph = new Chart('EngAserGraph', {
            type: 'bar',
            // responsive: true,
            data: {
              labels: this.engStandard,

              datasets: [
                {
                  label: '0 Level',
                  data: this.engLevel0,
                  backgroundColor: '#db3236',
                  borderColor: '#db3236',
                  fill: true,

                },

                {
                  label: 'Letter',
                  data: this.engLetter,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Word',
                  data: this.engWord,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Sentence',
                  data: this.engSentence,
                  backgroundColor: '#0F9D58',
                  borderColor: '#0F9D58',
                  fill: true
                },
                {
                  label: 'Paragraph',
                  data: this.engPara,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true
                },
                {
                  label: 'Story',
                  data: this.engStory,
                  backgroundColor: '#6C3483',
                  borderColor: '#6C3483',
                  fill: true
                }

              ],

            },
            options: {


              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',
                  }
                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    max: 100,
                    callback: function (value) {
                      return value + "%"
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Percentage',

                  }

                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white'
                }
              }
            }
          });

          this.loading = false;
        });

      }
      else {
        console.log(this.Examtype);
        
        this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi1to4("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'").subscribe((data: any) => {
          data.map((obj) => {
            obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.mathlevel0percentage = ((obj.mathlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.mathBeginnerLevelpercentage = ((obj.mathbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.mathBasicLevelpercentage = ((obj.mathbasic * 100) / obj.totalstudent).toFixed(2);


          });

          this.standarddataList = data;
          this.standarddataList = this.standarddataList.sort((a, b) => (a.standard - b.standard));
          data.forEach(X => {
            this.onefourstandardlabel.push(X.standard);
            this.level0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.Beginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.Basic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.mathslevel0.push(((X.mathlevel0 * 100) / X.totalstudent).toFixed(2));
            this.mathsBeginner.push(((X.mathbeginner * 100) / X.totalstudent).toFixed(2));
            this.mathsBasic.push(((X.mathbasic * 100) / X.totalstudent).toFixed(2));

          });
          var graph = document.getElementById("graph");
          if (this.stackchart2) { this.stackchart2.destroy(); }
          if (data.length != 0) {
            this.stackchart2 = new Chart('standardlang', {
              type: 'bar',
              // responsive: true,
              data: {
                labels: this.onefourstandardlabel,

                datasets: [
                  {
                    label: '0 Level',
                    data: this.level0,
                    backgroundColor: '#db3236',
                    borderColor: '#db3236',
                    fill: true,

                  },

                  {
                    label: 'Beginner',
                    data: this.Beginner,
                    backgroundColor: '#F4B400',
                    borderColor: '#F4B400',
                    fill: true
                  },

                  {
                    label: 'Basic',
                    data: this.Basic,
                    backgroundColor: '#0F9D58',
                    borderColor: '#0F9D58',
                    fill: true
                  },

                ],

              },
              options: {


                legend: {
                  display: true,
                  position: 'bottom',

                },

                scales: {
                  xAxes: [{

                    display: true,
                    stacked: true,
                    distribution: 'series',
                    scaleLabel: {
                      display: true,
                      labelString: 'Standard',

                    }

                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,

                    ticks: {
                      beginAtZero: true,
                      stepSize: 10,
                      max: 100,
                      callback: function (value) {
                        return value + "%"
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'Percentage',

                    }


                  }],

                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'white'
                  }
                }
              }
            });
          }
          else {
            graph.style.display = "none";
          }
          var graph1 = document.getElementById("graph1");
          if (this.stackchart3) { this.stackchart3.destroy(); }
          if (data.length != 0) {
            this.stackchart3 = new Chart('standardmaths', {
              type: 'bar',
              // responsive: true,
              data: {
                labels: this.onefourstandardlabel,

                datasets: [
                  {
                    label: '0 Level',
                    data: this.mathslevel0,
                    backgroundColor: '#db3236',
                    borderColor: '#db3236',
                    fill: true,

                  },

                  {
                    label: 'Beginner',
                    data: this.mathsBeginner,
                    backgroundColor: '#F4B400',
                    borderColor: '#F4B400',
                    fill: true
                  },

                  {
                    label: 'Basic',
                    data: this.mathsBasic,
                    backgroundColor: '#0F9D58',
                    borderColor: '#0F9D58',
                    fill: true
                  },

                ],

              },
              options: {


                legend: {
                  display: true,
                  position: 'bottom',

                },

                scales: {
                  xAxes: [{

                    display: true,
                    stacked: true,
                    distribution: 'series',
                    scaleLabel: {
                      display: true,
                      labelString: 'Standard',

                    }

                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,

                    ticks: {
                      beginAtZero: true,
                      stepSize: 10,
                      max: 100,
                      callback: function (value) {
                        return value + "%"
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'Percentage',

                    }


                  }],

                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'white'
                  }
                }
              }
            });
          }
          else {
            graph1.style.display = "none";
          }
          this.loading = false;
        })
      }

      if ((this.selectfiyear == '2022-2023' || this.selectfiyear == '2023-2024' || this.selectfiyear == '2024-2025') && (this.Examtype == 'Baseline' || this.Examtype == 'Midline' || this.Examtype == 'Endline') && this.pgid != 'P9') {
        this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to122022("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and academicyear = '" + this.selectfiyear + "'").subscribe((data: any) => {

          data.map((obj) => {
            obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.IntermediateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
          })

          this.fivstandarddataList = data;

          this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
          this.fivstandarddataList.forEach(X => {
            this.fivstandardlabel.push(X.standard);
            this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.fivIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
            this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

          });

          var graph5 = document.getElementById("graph5");

          if (this.stackchart1) { this.stackchart1.destroy(); }
          if (data.length != 0) {
            this.stackchart1 = new Chart('standardfiv', {
              type: 'bar',
              // responsive: true,
              data: {
                labels: this.fivstandardlabel,

                datasets: [
                  {
                    label: '0 Level(0)',
                    data: this.fivelevel0,
                    backgroundColor: '#db3236',
                    borderColor: '#db3236',
                    fill: true,

                  },

                  {
                    label: 'Beginner(26 >= 1)',
                    data: this.fiveBeginner,
                    backgroundColor: '#F4B400',
                    borderColor: '#F4B400',
                    fill: true
                  },

                  {
                    label:  'Basic(41 >= 26)',
                    data: this.fiveBasic,
                    backgroundColor: '#0F9D58',
                    borderColor: '#0F9D58',
                    fill: true
                  },

                  {
                    label: 'Intermediate(61 >= 41)',
                    data: this.fivIntermediate,
                    backgroundColor: '#4285F4',
                    borderColor: '#4285F4',
                    fill: true
                  },

                  {
                    label: 'Advance(100 >= 61)',
                    data: this.fiveAdvance,
                    backgroundColor: '#6C3483',
                    borderColor: '#6C3483',
                    fill: true
                  },



                ],

              },
              options: {


                legend: {
                  display: true,
                  position: 'bottom',

                },

                scales: {
                  xAxes: [{

                    display: true,
                    stacked: true,
                    distribution: 'series',
                    scaleLabel: {
                      display: true,
                      labelString: 'Standard',

                    }

                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,

                    ticks: {
                      beginAtZero: true,
                      stepSize: 10,
                      max: 100,
                      callback: function (value) {
                        return value + "%"
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'Percentage',

                    }


                  }],

                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'white'
                  }
                }
              }
            });
          }
          else {
            graph5.style.display = "none";
          }
          this.loading = false;
        })
      }
      else {
        console.log("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "'");
        
        this.service.GetCALAssessmentLevelStdGraphBaselinesayhogi5to12("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
          console.log(data);

          data.map((obj) => {
            obj.level0percentage = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
            obj.BeginnerLevelpercentage = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
            obj.BasicLevelpercentage = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
            obj.IntermidiateLevelpercentage = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
            obj.AdvanceLevelpercentage = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);

          })

          this.fivstandarddataList = data;
          this.fivstandarddataList = this.fivstandarddataList.sort((a, b) => (a.standard - b.standard));
          data.forEach(X => {
            this.fivstandardlabel.push(X.standard);
            this.fivelevel0.push(((X.langlevel0 * 100) / X.totalstudent).toFixed(2));
            this.fiveBeginner.push(((X.langbeginner * 100) / X.totalstudent).toFixed(2));
            this.fiveBasic.push(((X.langbasic * 100) / X.totalstudent).toFixed(2));
            this.fiveIntermediate.push(((X.langintermediate * 100) / X.totalstudent).toFixed(2));
            this.fiveAdvance.push(((X.langadvance * 100) / X.totalstudent).toFixed(2));

          });

          if (this.stackchart1) { this.stackchart1.destroy(); }
          var graph5 = document.getElementById("graph5");
          if (data.length != 0) {
            this.stackchart1 = new Chart('standardfiv', {
              type: 'bar',
              // responsive: true,
              data: {
                labels: this.fivstandardlabel,

                datasets: [
                  {
                    label: '0 Level(0)',
                    data: this.fivelevel0,
                    backgroundColor: '#db3236',
                    borderColor: '#db3236',
                    fill: true,

                  },

                  {
                    label: 'Beginner(26 >= 1)',
                    data: this.fiveBeginner,
                    backgroundColor: '#F4B400',
                    borderColor: '#F4B400',
                    fill: true
                  },

                  {
                    label:  'Basic(41 >= 26)',
                    data: this.fiveBasic,
                    backgroundColor: '#0F9D58',
                    borderColor: '#0F9D58',
                    fill: true
                  },
                  {
                    label: 'Intermediate(61 >= 41)',
                    data: this.fiveIntermediate,
                    backgroundColor: '#4285F4',
                    borderColor: '#4285F4',
                    fill: true
                  },
                  {
                    label:'Advance(100 >= 61)',
                    data: this.fiveAdvance,
                    backgroundColor: '#6C3483 ',
                    borderColor: '#6C3483',
                    fill: true
                  },

                ],

              },
              options: {


                legend: {
                  display: true,
                  position: 'bottom',

                },

                scales: {
                  xAxes: [{
                    display: true,
                    stacked: true,
                    distribution: 'series',
                    scaleLabel: {
                      display: true,
                      labelString: 'Standard',
                    }
                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,

                    ticks: {
                      beginAtZero: true,
                      stepSize: 10,
                      max: 100,
                      callback: function (value) {
                        return value + "%"
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'Percentage',

                    }


                  }],

                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'white'
                  }
                }
              }
            });
          }
          else {
            graph5.style.display = "none";
          }
          this.loading = false;

        })
      }
    }

    if (this.selectfiyear == '2023-2024' && this.pgid == 'P9') {
      this.service.GetMyEschoolAssessmentLevelStdGraph2022sayhogi1to4("where partner='" + this.UserId + "' and examtype='" + this.Examtype + "' and pgid = '" + this.pgid + "' and academicyear = '" + this.selectfiyear + "'").subscribe((data: any) => {

        this.standarddataList = [];
        this.mathslevel0 = [];
        this.mathsBeginner = [];
        this.mathsBasic = [];
        this.mathsIntermediate = [];
        this.mathsAdvanced = [];

        this.standarddataList = data;
        this.standarddataListCount = data.length;

        this.standarddataList.map((obj) => {
          obj.langlevel0 = ((obj.langlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.langbeginner = ((obj.langbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.langbasic = ((obj.langbasic * 100) / obj.totalstudent).toFixed(2);
          obj.langintermediate = ((obj.langintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.langadvance = ((obj.langadvance * 100) / obj.totalstudent).toFixed(2);
          // obj.absent = ((obj.absent * 100) / obj.totalstudent).toFixed(2);
          obj.mathlevel0 = ((obj.mathlevel0 * 100) / obj.totalstudent).toFixed(2);
          obj.mathbeginner = ((obj.mathbeginner * 100) / obj.totalstudent).toFixed(2);
          obj.mathbasic = ((obj.mathbasic * 100) / obj.totalstudent).toFixed(2);
          obj.mathintermediate = ((obj.mathintermediate * 100) / obj.totalstudent).toFixed(2);
          obj.mathadvance = ((obj.mathadvance * 100) / obj.totalstudent).toFixed(2);
          // obj.mathabsent = ((obj.mathabsent * 100) / obj.totalstudent).toFixed(2);

        });

        data = data.sort((a, b) => (a.standard - b.standard));

        data.forEach(y => {
          this.onefourstandardlabel.push(y.standard);
          this.level0.push(y.langlevel0);
          this.Beginner.push(y.langbeginner);
          this.Basic.push(y.langbasic);
          this.Intermediate1.push(y.langintermediate);
          this.Advance1.push(y.langadvance);
          // this.absent.push(y.absent);
          this.mathslevel0.push(y.mathlevel0);
          this.mathsBeginner.push(y.mathbeginner);
          this.mathsBasic.push(y.mathbasic);
          this.mathsIntermediate.push(y.mathintermediate);
          this.mathsAdvanced.push(y.mathadvance);
          // this.mathabsent.push(y.mathabsent);

        });

        if (this.stackchart2) {
          this.stackchart2.destroy();
        }
        if (this.stackchart31) {
          this.stackchart31.destroy();
        }
        this.stackchart2 = new Chart('standardlang', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.onefourstandardlabel,

            datasets: [
              {
                label: '0 Level',
                data: this.level0,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },

              {
                label: 'Letter',
                data: this.Beginner,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label: 'Word',
                data: this.Basic,
                backgroundColor: '#0F9D58',
                borderColor: '#0F9D58',
                fill: true
              },
              {
                label: 'Paragraph',
                data: this.Intermediate1,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true
              },
              {
                label: 'Story',
                data: this.Advance1,
                backgroundColor: '#6C3483',
                borderColor: '#6C3483',
                fill: true
              },
              // {
              //   label: 'Absent',
              //   data: this.absent,
              //   backgroundColor: '#808080',
              //   borderColor: '#808080',
              //   fill: true
              // },

            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });

        this.stackchart31 = new Chart('standardmaths', {
          type: 'bar',
          // responsive: true,
          data: {
            labels: this.onefourstandardlabel,

            datasets: [
              {
                label: '0 Level',
                data: this.mathslevel0,
                backgroundColor: '#db3236',
                borderColor: '#db3236',
                fill: true,

              },

              {
                label: '1 Digit Number',
                data: this.mathsBeginner,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },

              {
                label: '2 Digit Number',
                data: this.mathsBasic,
                backgroundColor: '#0F9D58',
                borderColor: '#0F9D58',
                fill: true
              },
              {
                label: 'Subtraction',
                data: this.mathsIntermediate,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true
              },
              {
                label: 'Division',
                data: this.mathsAdvanced,
                backgroundColor: '#6C3483',
                borderColor: '#6C3483',
                fill: true
              },
              // {
              //   label: 'Absent',
              //   data: this.mathabsent,
              //   backgroundColor: '#808080',
              //   borderColor: '#808080',
              //   fill: true
              // },

            ],

          },
          options: {


            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',

                }

              }],
              yAxes: [{
                display: true,
                stacked: true,

                ticks: {
                  beginAtZero: true,
                  stepSize: 10,
                  max: 100,
                  callback: function (value) {
                    return value + "%"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage',

                }

              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] != 0; // or != 0 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });

        this.loading = false;
      })
      this.service.GetMyESchoolAserPartnerGraph("where MyESchoolAssessmentLevelStudent.partnerid='" + this.UserId + "'and exam='" + this.Examtype + "' and Center.pgid = '" + this.pgid + "' and MyESchoolAssessmentLevelStudent.year = '" + this.selectfiyear + "'").subscribe((data: any) => {

        if (this.bonusGraph) { this.bonusGraph.destroy(); };

        this.q1 = [];
        this.q2 = [];
        this.q3 = [];
        this.q4 = [];
        this.bonusListCount = 0;

        this.bonusList = data;
        this.bonusListCount = data.length;

        if (this.bonusListCount) {

          data.forEach(y => {
            this.standardlabel.push('STD ' + y.standard);
            this.q1.push(((y.q1)).toFixed(2));
            this.q2.push(((y.q2)).toFixed(2));
            this.q3.push(((y.q3)).toFixed(2));
            this.q4.push(((y.q4)).toFixed(2));
          })

          this.bonusGraph = new Chart('bonusgraph', {
            type: 'horizontalBar',
            data: {
              labels: this.standardlabel,
              datasets: [
                {
                  label: 'Q1-Calculating Time',
                  data: this.q1,
                  backgroundColor: '#4285F4',
                  borderColor: '#4285F4',
                  fill: true,

                },

                {
                  label: 'Q2-Applying Unitary Method',
                  data: this.q2,
                  backgroundColor: '#3cba54',
                  borderColor: '#3cba54',
                  fill: true,
                },

                {
                  label: 'Q3-Simple operationa Financial Meeting',
                  data: this.q3,
                  backgroundColor: '#F4B400',
                  borderColor: '#F4B400',
                  fill: true
                },

                {
                  label: 'Q4-Computing Discounted Price',
                  data: this.q4,
                  backgroundColor: '#cc0000',
                  borderColor: '#cc0000',
                  fill: true
                },

              ],
            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    // labelString: 'ASER - Bonus', 
                    fontSize: 15,
                  },
                  position: 'bottom',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Standard',
                    fontSize: 15,
                  },

                  ticks: {
                    reverse: true,
                  },

                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'white',

                }
              },
              aspectRatio: 3
            }
          });

          this.loading = false;
        }
      })
    }

  }

}
